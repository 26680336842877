<!-- 点名详情 -->
  <template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="platformType == 3">
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(-2)">学员学习记录</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">个人学习记录</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">点名详情</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(-3)">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(-2)">学员信息</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员记录</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">点名详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div
            style="
              margin-bottom: 15px;
              font-size: 1rem;
              align-items: flex-start;
              padding-left: 0.5rem;
            "
            class="searchbox"
          >
            <span>姓名：{{ row.userName || row.sourceUserName }}</span>
            <span style="margin: 0 15px"
              >身份证号：{{ row.idcard || row.sourceUserIdcard }}</span
            >
            <span>电话：{{ row.mobile || row.sourceUserMobile }}</span>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableDataz"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="课节名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
                min-width="300"
              />
              <el-table-column
                label="视频播放位置"
                align="right"
                :show-overflow-tooltip="true"
                min-width="150"
              >
                <template slot-scope="scope">{{
                  getTime(scope.row.sumTime) || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="点名确认时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "", // 课程名称
      kpointName: "", // 小节名称
      studystate: "", // 学习状态
      studylist: [], // 学习状态下拉数据
      activeName: "first",
      row: {}, // 缓存
      projectId: "",
      tableDataz: [], // 列表数据
      platformType: "", // 监管 -3
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {
    this.init();
    this.getDataz();
  },
  methods: {
    init() {
      this.row = JSON.parse(sessionStorage.getItem("row"));
      this.projectId = sessionStorage.getItem("projectIdYw") || "";
      this.getTableHeight();
      this.platformType = this.$route.query.platformType || "";
    },
    // 获取列表数据
    getDataz() {
      const params = {
        projectId: this.row.projectId || this.projectId,
        studyId: this.$route.query.studyId,
      };
      this.$post("/biz/course/study/queryRollCallRecord", params)
        .then((res) => {
          this.tableDataz = res.data;
        })
        .catch(() => {
          return;
        });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15 + 30;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
</style>